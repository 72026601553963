<template>
    <div class="backdrop">
      <div class="card">
        <header class="card-header">
            <h2 class="header">{{ $t("common.success") }}</h2>
        </header>
        <section class="card-body">
          <p class="text">{{ $t("registration.verified") }}</p>
        </section>
        <footer class="card-footer">
          <button @click="returnHome">{{ $t("payment.return_home") }}</button>
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Verification",
    methods: {
      returnHome() {
        this.$router.push("/");
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/theme.scss";
  
  .backdrop{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 40vw;
  }
  .card {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: auto;
    height: auto;
  }
  
  .card-header {
    display: flex;
    position: relative;
    border-bottom: 1px solid #eeeeee;
    justify-content: space-between;
  }
  
  .header {
    @extend %primary-pattern;
    position: relative;
    text-align: center;
    width: 100%;
    padding: 10px 0;
  }
  
  .card-footer {
    display: flex;
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .card-body {
    position: relative;
    padding: 15px;
    text-align: center;
  }
  
  .text {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  button {
    cursor: pointer;
    text-align: center;
    padding: 10px 0;
    border: none;
  }
  
  @media only screen and (max-width: 600px) {
    .backdrop{
      height: 50vh;
      width: 70vw;
    }
  }
  
  </style>